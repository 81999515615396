<template>
   <div class="top_order_info flex_row_start_center">
      <div class="top_logo">
         <div :style="`background-image:url(${configInfo.main_site_logo})`"></div>
      </div>
      <div class="top_info_progress flex_row_end_center">
         <div class="progress_item">
            <div class="progress flex_row_center_center">
               <span class="active">1</span>
               <div class="progress_line">
                  <div :class="{ content: true, active: false, current: false }"></div>
               </div>
            </div>
            <p style="margin-left: -11px">{{ L['确认订单'] }}</p>
         </div>

         <div class="progress_item">
            <div class="progress flex_row_center_center">
               <span :class="{ active: false }">{{ pre_params.data.ifcart == 'true' ? 2 : 1 }}</span>
               <div class="progress_line">
                  <div :class="{ content: true, active: false }"></div>
               </div>
            </div>
            <p>{{ L['付款'] }}</p>
         </div>

         <div class="progress_item" style="margin-left: -24px">
            <div class="progress flex_row_end_center">
               <span :class="{ active: false }">{{ pre_params.data.ifcart == 'true' ? 3 : 2 }}</span>
            </div>
            <p>{{ L['支付成功'] }}</p>
         </div>

      </div>
   </div>
   <div class="confirm_order_main">
      <div class="confirm_order_container">
         <div class="receive_info">
            <template v-if="orderAddress.data">
               <div class="receive_info_title">
                  <span>{{ L['收货信息'] }}</span>
                  <div @click="addAddress">{{ L['新建地址'] }}</div>
               </div>
               <div class="receive_info_user">
                  {{ orderAddress.data.memberName }}<span>{{ orderAddress.data.telMobile }}</span>{{
                     orderAddress.data.addressAll
                  }}
                  {{ orderAddress.data.detailAddress }}
                  <div @click="changeAddress">{{ L['更换地址'] }}</div>
               </div>
               <div class="receive_info_type">
                  <span>{{L['发货方式：']}}</span>
                  <el-radio v-model="shipments" label="1">{{L['齐套发货']}}</el-radio>
                  <el-radio v-model="shipments" label="2" v-if="!(allData.data.totalGoodsNum == 1 && goods_data.data.length == 1
   && goods_data.data[0].storeCartList.length == 1 && goods_data.data[0].storeCartList[0].buyNum == 1)">{{L['分批发货']}}</el-radio>

               </div>
            </template>
            <template v-else>
               <div class="receive_info_title">
                  <span>{{ L['收货信息'] }}</span>
               </div>
               <div class="receive_info_user_empty">
                  <div @click="addAddress">{{ L['新建地址'] }}</div>
               </div>
            </template>
         </div>
         <div class="store_item_title" v-if="goods_data.data.length > 0">
            <ul>
               <li>{{L['产品信息']}}</li>
               <li></li>
               <li></li>
               <li>{{L['单价（元）']}}</li>
               <li>{{L['数量']}}</li>
               <li>{{L['小计（元）']}}</li>
            </ul>
         </div>
         <template v-for="(storeItem, index) in goods_data.data" :key="index">
            <div class="store_item" :class="{ store_item_none: index == 0 }">
               <div class="store_name">
                  <i class="iconfont icondianpu1"></i>
                  <span>{{ storeItem.storeName }}</span>
               </div>
               <div class="good_container">
                  <div v-for="(goodItem, goodsIndex) in storeItem.storeCartList" :key="goodsIndex" class="good_item">
                     <div class="flex_row_start_center">
                        <div class="good_info flex_row_start_center">
                           <div class="good_image_con">
                              <div class="virtual_tag" v-if="false">{{L['虚拟']}}</div>
                              <img class="good_image" :src="goodItem.goodsImage" alt />
                           </div>
                           <div class="good_info_text flex_column_between_start">
                              <div>
                                 <div class="good_name overflow_ellipsis_two">{{ goodItem.goodsName }}</div>
                                 <div class="good_spec overflow_ellipsis">{{ goodItem.specValues }}</div>
                              </div>
                              <div>
                                 <p class="productCode" v-if="goodItem.goodsSpec">规格型号:{{ goodItem.goodsSpec }}</p>
                                 <p class="goods_des_bottom">
                                    {{ goodItem.produceState == 1 ? L['现货'] : goodItem.produceState == 2 ? L['半成品'] : L['待产'] }}
                                    <span>{{L['发货日']}}: {{ goodItem.deliverTime }}{{L['天']}}</span>
                                 </p>
                              </div>

                           </div>
                        </div>
                        <!-- 商品参数 -->
                        <div class="goods_message">
                           <el-scrollbar>
                              <div v-for="attr in goodItem.attributeList" :key="attr">
                                 {{ attr.attributeName }}：{{ attr.attributeValue }}
                              </div>
                           </el-scrollbar>
                        </div>
                        <div class="good_price flex_row_center_center">
                           <span>¥{{ goodItem.goodsPrice || '--' }}</span>
                        </div>
                        <div class="num">{{ goodItem.buyNum }}</div>
                        <div class="good_price_sum" v-if="goodItem.totalAmount">¥{{ goodItem.totalAmount }}</div>
                        <div class="good_price_sum" v-else>{{L['需询价']}}</div>

                     </div>
                     <!-- 物料信息 -->
                     <div class="good_logistics">
                        <div class="good_logistics_left flex_row_start_center">
                           <div>{{L['您的物料信息：']}}</div>
                           <template v-if="goodItem.editType">
                              <el-input :placeholder="L['请输入产品名称']" v-model="goodItem.customProductNameEdit" show-word-limit
                                 maxlength="30" />
                              <el-input :placeholder="L['请输入产品自定义型号']" v-model="goodItem.customSpecEdit" show-word-limit
                                 maxlength="30" />
                           </template>
                           <template v-else>
                              <span v-if="goodItem.customProductName">{{ goodItem.customProductName }}</span>
                              <span v-if="goodItem.customSpec">{{ goodItem.customSpec }}</span>
                           </template>
                        </div>
                        <div class="good_logistics_right flex_row_start_center">
                           <template v-if="goodItem.editType">
                              <div class="good_logistics_right_btn" @click="saveEdit(index, goodsIndex)">{{L['保存']}}</div>
                              <div class="good_logistics_right_btn" @click="cancleEdit(index, goodsIndex)">{{L['取消']}}</div>
                           </template>
                           <div class="good_logistics_right_edit" @click="editOpen(index, goodsIndex)" v-else>
                              <i class="iconfont iconbianji"></i>
                              {{L['请']}}{{ (goodItem.customProductName || goodItem.customSpec) ? L['编辑'] : L['填写'] }}{{L['您的物料编号信息']}}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div v-for="(sendItem, sendIndex) in storeItem.sendProductList" :key="sendIndex"
                     class="good_item flex_row_start_center">
                     <div class="good_info flex_row_start_center">
                        <img class="good_image" :src="sendItem.goodsImage" alt />
                        <div class="good_info_text">
                           <div class="good_name overflow_ellipsis_two"><span class="giftProduct">{{ L['赠品'] }}</span>{{
                              sendItem.goodsName
                           }}
                           </div>
                           <div class="good_spec overflow_ellipsis">{{ L['赠完为止'] }}</div>
                        </div>
                     </div>
                     <div class="good_price"></div>
                     <div class="num">*{{ sendItem.num }}</div>
                  </div>
               </div>
            </div>

            <div class="store_item_bottom">
               <p>{{L['优惠券']}}</p>
               <div class="store_coupon">
                  <div class="store_coupon_con flex_row_start_center">
                     <span class="title" v-if="storeItem.availableCouponList.length == 0">{{ L['暂无可用优惠券'] }}</span>
                     <el-select class="voice_select" v-else v-model="couponCode[index]"
                        @change="changeCoupon(storeItem, index)" :placeholder="L['请选择']">
                        <el-option :value="L['不使用优惠券']">{{ L['不使用优惠券'] }}</el-option>
                        <el-option v-for="(couponItem, index) in storeItem.availableCouponList" :key="index"
                           :label="couponItem.couponName + (couponItem.couponType == 2 ? `(${couponItem.value}${L['折']})` : `(${L['优惠']}${couponItem.value}元)`)"
                           :value="couponItem.couponCode"></el-option>
                     </el-select>
                  </div>
               </div>
            </div>

            <div class="store_item_bottom">
               <p>{{L['备注信息']}}</p>
               <div class="store_item_bottom_item">
                  <span>{{L['采购单号：']}}</span>
                  <el-input v-model="storeItem.purchaseSn" :placeholder="L['请备注贵公司的标准采购单号']" show-word-limit maxlength="30"
                     @input="inputPurchase" @focus="focusInput(index)" @blur="blurInput" />
               </div>
               <div class="store_item_bottom_item">
                  <span>{{L['备注：']}}</span>
                  <el-input type="textarea" v-model="storeItem.orderRemark" :placeholder="L['请输入备注信息，如没有特殊要求可不填写']"
                     show-word-limit maxlength="100" resize="none" />
               </div>
               <div class="store_item_bottom_item store_item_bottom_date">
                  <span>{{L['期望交货日期：']}}</span>
                  <el-date-picker value-format="YYYY-MM-DD" v-model="storeItem.expectDeliverTime" type="date"
                     :placeholder="L['请选择']" :disabled-date="disabledDate">
                  </el-date-picker>
                  <span>{{L['（此日期仅作参考。如产品发货日不满足期望交货日期则订单需供应商审核通过后再进行付款。）']}}</span>
               </div>
               <div class="store_item_bottom_money">
                  <p class="store_item_bottom_money_p">{{L['产品金额：']}}<span>{{L['￥']}}{{ storeItem.goodsAmount }}</span></p>
                  <p class="store_item_bottom_money_p">
                     {{L['运费：']}}<span>{{ storeItem.expressFee ? `${L['￥']}${storeItem.expressFee}` : L['免运费'] }}</span>
                  </p>

                  <p class="store_item_bottom_money_p">
                     {{L['优惠：']}}<span>{{ storeItem.totalDiscount ? `-${L['￥']}${storeItem.totalDiscount}` : '0' }}</span>
                  </p>

                  <p>{{L['小计：']}}<span>{{L['￥']}}{{ storeItem.totalAmount }}</span></p>
               </div>
            </div>
         </template>






         <div class="store_item_bottom" style="margin-top:20px">
            <p>{{L['平台优惠券']}}</p>

            <div class="coupon_box">
               <div class="coupon_info">

                  <div class="paging flex_row_between_start" v-if="order_settle_info.platformCouponList.length">

                     <div class="flex_row_start_center">
                        <div class="tab_item flex_column_between_center" :class="{ chosen: curTab == 1 }"
                           @click="curTab = 1">
                           <p>{{L['可用优惠券']}}</p>
                           <div class="b_line"></div>
                        </div>

                        <div class="tab_item flex_column_between_center" :class="{ chosen: curTab == 2 }"
                           @click="curTab = 2">
                           <p>{{L['不可用优惠券']}}</p>
                           <div class="b_line"></div>
                        </div>
                     </div>


                     <div class="flex_row_end_center">
                        <img src="@/assets/previous_page.png" @mouseover="previous(0)" v-if="!previous_page" />
                        <img src="@/assets/previous_page_xuan.png" @mouseout="previou(0)" @click="upper"
                           v-if="previous_page" />
                        <span class="page flex_row_center_center">{{ pagesize }}</span>
                        <span class="xie">/</span>
                        <span class="total flex_row_center_center">{{ total }}</span>
                        <img src="@/assets/next_page.png" @mouseover="previous(1)" v-if="!next_page" />
                        <img src="@/assets/next_page_xuan.png" @mouseout="previou(1)" @click="lower" v-if="next_page" />
                     </div>

                  </div>
                  <div class="coupon_list">

                     <template v-if="curTab == 1">
                        <template v-for="(item, index) in order_settle_info.platformCouponList" :key="index">
                           <div class="coupon_cont flex_row_between_center" v-if="
                              item.checked == false &&
                              index + 1 >= rise &&
                              index + 1 <= end
                           " @click="platformDiscount(item.couponCode)">
                              <div class="coupons">
                                 {{
                                    item.couponType == 1
                                       ? L['满减券']
                                       : item.couponType == 2
                                          ? L['折扣券']
                                          : L['随机金额券']
                                 }}
                              </div>
                              <div class="coupon_cont_box flex_column_between_start">
                                 <div class="money" v-if="item.couponType != 2">
                                    <span>{{L['￥']}}</span>{{ item.discount }}
                                 </div>
                                 <div class="money" v-else>
                                    {{ item.value }}<span>{{ L['折'] }}</span>
                                 </div>

                                 <div class="full_minus flex_row_center_center">
                                    {{ item.content }}
                                 </div>
                                 <div class="coupon_time">{{ item.useTime }}</div>
                                 <div class="user_rule">
                                    <span>{{ L['使用规则：'] }}</span>{{ item.description }}
                                 </div>
                              </div>
                              <img src="../../assets/coupon.png" alt="" class="img_coupon" />
                           </div>
                           <div class="coupon_cont flex_row_between_center coupon_contone" v-if="
                              item.checked == true &&
                              index + 1 >= rise &&
                              index + 1 <= end
                           " @click="platformDiscount(L['不使用优惠券'])">
                              <div class="coupons coupons_one">
                                 {{
                                    item.couponType == 1
                                       ? L['固定金额券']
                                       : item.couponType == 2
                                          ? L['折扣券']
                                          : L['随机金额券']
                                 }}
                              </div>
                              <div class="coupon_cont_box flex_column_between_start">
                                 <div class="money cp_chosen" v-if="item.couponType != 2">
                                    <span>{{L['￥']}}</span>{{ item.discount }}
                                 </div>
                                 <div class="money cp_chosen" v-else>
                                    {{ item.value }}<span>{{ L['折'] }}</span>
                                 </div>

                                 <div class="full_minus flex_row_center_center">
                                    {{ item.content }}
                                 </div>
                                 <div class="coupon_time">{{ item.useTime }}</div>
                                 <div class="user_rule">
                                    <span>{{ L['使用规则：'] }}</span>{{ item.description }}
                                 </div>
                              </div>
                              <img src="@/assets/goods/check_marks.png" alt="" class="coupon_img" />
                           </div>
                        </template>


                        <SldCommonEmpty v-if="!order_settle_info.platformCouponList.length" totalHeight="200px"
                           :tip="L['暂无优惠券']">
                        </SldCommonEmpty>
                     </template>


                     <template v-if="curTab == 2">
                        <template v-for="(item, index) in order_settle_info.disabledCouponList" :key="index">
                           <div class="coupon_cont flex_row_between_center" v-if="
                              item.checked == false &&
                              index + 1 >= rise &&
                              index + 1 <= end
                           " @click="platformDiscount(item.couponCode)">
                              <div class="coupons">
                                 {{
                                    item.couponType == 1
                                       ? L['固定金额券']
                                       : item.couponType == 2
                                          ? L['折扣券']
                                          : L['随机金额券']
                                 }}
                              </div>
                              <div class="coupon_cont_box flex_column_between_start">
                                 <div class="money" v-if="item.couponType != 2">
                                    <span>{{L['￥']}}</span>{{ item.value }}
                                 </div>
                                 <div class="money" v-else>
                                    {{ item.value }}<span>{{ L['折'] }}</span>
                                 </div>

                                 <div class="full_minus flex_row_center_center">
                                    {{ item.content }}
                                 </div>
                                 <div class="coupon_time">{{ item.useTime }}</div>
                                 <div class="user_rule">
                                    <span>{{ L['使用规则：'] }}</span>{{ item.description }}
                                 </div>
                              </div>
                              <img src="../../assets/coupon.png" alt="" class="img_coupon" />
                           </div>
                           <div class="coupon_cont flex_row_between_center coupon_contone" v-if="
                              item.checked == true &&
                              index + 1 >= rise &&
                              index + 1 <= end
                           " @click="platformDiscount(L['不使用优惠券'])">
                              <div class="coupons coupons_one">
                                 {{
                                    item.couponType == 1
                                       ? L['满减券']
                                       : item.couponType == 2
                                          ? L['折扣券']
                                          : L['随机金额券']
                                 }}
                              </div>
                              <div class="coupon_cont_box flex_column_between_start">
                                 <div class="money cp_chosen" v-if="item.couponType != 2">
                                    <span>{{L['￥']}}</span>{{ item.value }}
                                 </div>
                                 <div class="money cp_chosen" v-else>
                                    {{ item.value }}<span>{{ L['折'] }}</span>
                                 </div>

                                 <div class="full_minus flex_row_center_center">
                                    {{ item.content }}
                                 </div>
                                 <div class="coupon_time">{{ item.useTime }}</div>
                                 <div class="user_rule">
                                    <span>{{ L['使用规则：'] }}</span>{{ item.description }}
                                 </div>
                              </div>
                              <img src="@/assets/goods/check_marks.png" alt="" class="coupon_img" />
                           </div>
                        </template>

                        <SldCommonEmpty v-if="!order_settle_info.disabledCouponList.length" totalHeight="200px"
                           :tip="L['暂无优惠券']">
                        </SldCommonEmpty>

                     </template>



                  </div>

               </div>
            </div>
            <div class="xian">
               <p></p>
            </div>
         </div>




         <div class="order_total_settle">
            <div class="order_total_settle_select flex_row_end_center">
               <!-- <div>已选<span>{{ allData.data.totalGoodsNum }}</span>件产品</div> -->
               <div class="flex_row_end_center">{{L['产品总额']}}:<span style=" min-width: 103px;display: block;">{{L['¥']}}{{
      order_settle_info.goodsAmount
}}</span></div>
            </div>


            <div class="order_total_settle_expressFee">
               {{L['运费：']}}<span>{{ order_settle_info.totalExpress ? `¥${order_settle_info.totalExpress}` : L['免运费'] }}</span>
            </div>

            <div class="order_total_settle_expressFee">
               {{L['优惠：']}}<span>{{ order_settle_info.totalDiscount ? `-¥${order_settle_info.totalDiscount}` : '0' }}</span>
            </div>

            <div class="order_total_settle_total">{{L['应付总额：']}}<span>{{L['¥']}} {{ order_settle_info.totalAmount }}</span></div>



            <div class="flex_row_end_center">
               <div class="goBuy" @click="goBuy" style="cursor: pointer;">{{ L['提交订单'] }}</div>
            </div>
         </div>

         <!-- 商品无货缺货弹窗 start -->
         <el-dialog :title="no_good_info.data.stateValue" v-model="out_stock_dialog" customClass="out_store_width"
            :before-close="handleClose" lock-sroll="false">
            <div class="out_stock_dialog">
               <div v-for="(outStockItem, index) in no_good_info.data.storeCartList" :key="index"
                  class="good_item flex">
                  <img :src="outStockItem.image" alt />
                  <div class="good_info">
                     <span class="good_name overflow_ellipsis">{{ outStockItem.goodsName }}</span>
                     <div class="spec_num">
                        <span class="good_spec">{{ outStockItem.goodsSpec }}</span>
                        <span class="good_num">*{{ outStockItem.buyNum }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="btn_con flex_row_center_center" v-if="no_good_info.data.state == 2">
               <div class="return" @click="returnLastPage">{{ L['返回'] }}</div>
               <div class="remove" @click="clearFailureGoods">{{ L['移除无货产品'] }}</div>
            </div>
            <div class="btn_con flex_row_center_center" v-else>
               <div class="return red" @click="returnLastPage">{{ L['返回'] }}</div>
            </div>
         </el-dialog>
         <!-- 商品无货缺货弹窗 end -->

         <!-- 地址选择弹窗 start -->
         <el-dialog :title="L['更换地址']" v-model="show_select_address" customClass="select_address_width"
            :before-close="handleClose" lock-sroll="false">
            <div class="out_stock_dialog address_con">
               <div v-for="(item, index) in address_list.data" :key="index" @click="selectAddress(index)"
                  :class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }">
                  <span>{{ L['收货人'] }}：{{ item.memberName }}</span>
                  <span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
                  <div class="address_text" :title="item.addressAll + item.detailAddress">
                     <span>{{ L['收货地址'] }}：</span>
                     <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
                  </div>
                  <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
               </div>
            </div>
            <div class="btn_con flex_row_center_center">
               <div class="remove red" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
            </div>
         </el-dialog>
         <!-- 地址选择弹窗 end -->

         <!-- 积分抵扣弹框 -->
         <el-dialog :title="L['使用积分']" v-model="showIntList" width="550px" lock-scroll="false" top="9%">
            <div class="integral_avalible_order">
               <span>{{ L['可用积分'] }}</span>: <span>{{ allData.data.memberIntegral }}{{ L['积分'] }}</span>
            </div>
            <div class="use_integral_list_order">
               <div class="integral_item_order" v-for="(item, integralIndex) in allData.data.integralList"
                  :key="integralIndex" @click="tmpInt = item">
                  <span>{{ L['抵扣'] }}<font color="#e2231a">¥{{ item / allData.data.integralScale }}</font>{{ L['使用'] }}
                     <font color="#e2231a">{{ item }}
                     </font>{{ L['积分'] }}
                  </span>
                  <div>
                     <input type="radio" :value="item" :id="integralIndex" name="integral" :checked="tmpInt == item">
                     <label :for="integralIndex" class="integralLabel_order">
                        <div class="img_order"></div>
                     </label>
                  </div>
                  <div class="no_avalible_order" v-if="item > allData.data.memberIntegral"></div>
               </div>
            </div>
            <div class="integral_btn_order">
               <button class="integral_abandom_order" @click="integralAban">{{ L['暂不使用积分'] }}</button>
               <button class="integral_engage_order" @click="integralEng">{{ L['使用'] }}</button>
            </div>
         </el-dialog>
         <!-- 积分规则框 -->
         <el-dialog :title="L['使用规则']" v-model="showIntRule" width="390px" lock-scroll="false" top="10%">
            <div class="intRule_con">
               <div class="int_rule_item">{{ L['订单大于等于'] }}<font color="#e2231a">{{ intRuleList[1] }}{{ L['元'] }}</font>
                  {{ L['可用'] }};
               </div>
               <div class="int_rule_item">{{ L['积分支付不超过订单金额的'] }}<font color="#e2231a">{{ intRuleList[2] }}%</font>;
               </div>
               <div class="int_rule_item">{{ L['积分使用数量为'] }}<font color="#e2231a">{{ intRuleList[0] }}</font>
                  {{ L['的整数倍'] }}({{ intRuleList[0] }}{{ L['积分等于'] }}<font color="#e2231a">1{{ L['元'] }}</font>);
               </div>
            </div>
         </el-dialog>

         <SldAddressOperation v-if="addressVisibleFlag" @close="close" @refreshAddress="refreshAddress"
            :visibleFlag="true" :type="type" />
      </div>
   </div>
</template>

<script>
import SldCommonEmpty from "../../components/SldCommonEmpty.vue";
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted, watch, toRefs, computed } from "vue";
import {
   ElDialog,
   ElInput,
   ElSwitch,
   ElMessage,
   ElSelect,
   ElOption
} from "element-plus";
import { useRoute, useRouter } from "vue-router";
import SldAddressOperation from "../../components/AddressOperation";
import { useStore } from 'vuex'
export default {
   name: "ConfirmOrder",
   components: {
      ElDialog,
      ElInput,
      ElSwitch,
      ElSelect,
      ElOption,
      SldAddressOperation,
      SldCommonEmpty
   },
   setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore()
      const addressVisibleFlag = ref(false);
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage();
      const not_select = require("../../assets/buy/not_select.png");
      const selected = require("../../assets/buy/selected.png");
      const invoice_selected = require("../../assets/buy/invoice_selected.png");
      const couponCode = ref([])
      const platformCouponCode = ref("")
      const navlist = reactive({ data: [] });
      const show_change_address = ref(false);
      const out_stock_dialog = ref(false);
      const type = ref("add");
      const timer = ref(0); //定时器
      const no_good_info = reactive({ data: {} });
      const show_select_address = ref(false);
      const pre_params = reactive({ data: {} });
      const orderAddress = reactive({ data: "" });
      const address_list = reactive({ data: [] });
      const goods_data = reactive({ data: [] });
      const configInfo = ref(store.state.configInfo)
      const current_address_index = ref(0);
      const shipments = ref('1'); //发货方式
      const select_invoice_info = reactive({
         invoiceId: "",
         invoiceContent: "",
         invoiceTitle: ""
      });
      const selCouponStore = reactive({
         store: {},
         couponCode: ''
      })
      const rules = ref('')
      const invoice_list = reactive({ data: [] }); //发票列表
      const intRuleList = ref([])
      const invoice_info = reactive({
         data: {
            //新增发票信息
            bankAccount: "", //银行帐户(专用发票）
            bankName: "", //开户银行(专用发票）
            invoiceContent: 1, //发票内容：1-产品明细；2-产品类别
            invoiceTitle: "", //发票抬头(通用信息）
            invoiceType: 1, //发票类型：1-普通发票；2-增值税发票
            isDefault: false, //是否默认发票：0-非默认发票，1-默认发票
            receiverAddress: "", //收票人详细地址(通用信息）
            receiverEmail: "", //	收票邮箱(通用信息）
            receiverMobile: "", //收票人手机号(通用信息）
            receiverName: "", //收票人姓名(通用信息）
            registerAddr: "", //注册地址(专用发票）
            registerPhone: "", //注册电话(专用发票）
            taxCode: "", //纳税人识别号(通用信息）
            titleType: 1, //抬头类型：1-个人；2-公司
            companyName: "" //部门名称
         }
      });
      const order_settle_info = reactive({
         totalAmount: "",
         totalDiscount: "",
         platformCouponList: [],
         platformCouponCode: "",
         isFreeShipping: true,
      });
      const allData = reactive({ data: {} })
      const showIntList = ref(false)
      const showIntRule = ref(false)
      const chosenInt = ref(0)
      const tmpInt = ref(0)
      const purchIndex = ref('');
      const isPreventClick = ref(false)
      const dateState = reactive({
         disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
         }
      });
      const discountPlaform = computed(() => {
         let storeDiscounts = goods_data.data
            .map(i => Number(i.totalDiscount))
            .reduce((total, prev) => {
               return total + prev
            }, 0)

         return Number(allData.data.totalDiscount) - storeDiscounts
      })


      const previous_page = ref(false)
      const next_page = ref(false)
      const rise = ref(1) //优惠券起始
      const end = ref(8) //优惠券结束
      const total = ref(0) //优惠券结束
      const pagesize = ref(1) //优惠券结束
      const curTab = ref(1)




      const changeCoupon = (storeItem, index) => {
         selCouponStore.store = storeItem
         selCouponStore.couponCode = couponCode.value[index]
         confirmOrder(2)
      }

      //用于切换地址，使用优惠券，获取信息，运费等
      const confirmOrder = type => {
         let param = {};
         param.addressId = orderAddress.data.addressId;
         param.source = type;

         //新加的参数----start
         if (route.query.productSets) {
            param.products = decodeURIComponent(route.query.productSets)
         }

         if (route.query.sets) {
            param.sets = route.query.sets ? route.query.sets : 1
         }
         //新加的参数----end


         if (platformCouponCode.value == L['不使用优惠券']) {
            delete param.platformCouponCode;
         } else {
            param.platformCouponCode = platformCouponCode.value
         }


         let storeInfos = [];
         // if (goods_data.data.length) {
         goods_data.data.map(item => {
            let storeitem = {};
            if (selCouponStore.store.storeId == item.storeId) {
               storeitem.remark = selCouponStore.store.remark;
               storeitem.storeCouponCode = selCouponStore.couponCode == L['不使用优惠券'] ? "" : selCouponStore.couponCode;
               storeitem.storeId = item.storeId;

            } else {
               storeitem.remark = item.remark;
               storeitem.storeCouponCode = item.couponCode == L['不使用优惠券'] ? "" : item.couponCode;
               storeitem.storeId = item.storeId;
            }
            storeInfos.push(storeitem);
         });


         param.storeInfos = storeInfos
         param.isCart = true;




         proxy.$post("v3/business/front/orderOperate/confirm", param, 'json').then(res => {
            if (res.state == 200) {




               switch (type) {
                  case 1: {
                     goods_data.data = res.data.storeGroupList;
                     goods_data.data.forEach((item, i) => {
                        item.remark = "";
                        item.editType = false;
                        let default_coupon = item.availableCouponList.find(coupon => coupon.checked);
                        if (default_coupon) {
                           item.couponCode = default_coupon.couponCode;
                           couponCode.value[i] = default_coupon.couponCode
                        } else {
                           item.couponCode = "";
                           couponCode.value[i] = ""
                        }
                     })
                     break;
                  }

                  case 2: {
                     let tmpRes = res.data.storeGroupList
                     goods_data.data.map((item, index) => {
                        let default_coupon = tmpRes[index].availableCouponList.find((coupon) => coupon.checked)
                        if (default_coupon) {
                           item.couponCode = default_coupon.couponCode;
                           couponCode.value[index] = default_coupon.couponCode ? default_coupon.couponCode : L['不使用优惠券']
                        } else {
                           item.couponCode = L['不使用优惠券'];
                           couponCode.value[index] = L['不使用优惠券']
                        }

                        item.expressFee = tmpRes[index].expressFee
                        item.totalDiscount = tmpRes[index].totalDiscount
                     })
                  }
               }


               allData.data = res.data;
               order_settle_info.totalAmount = res.data.totalAmount;
               order_settle_info.goodsAmount = res.data.goodsAmount;

               order_settle_info.totalDiscount = res.data.totalDiscount;
               order_settle_info.platformCouponList = res.data.availableCouponList;
               order_settle_info.disabledCouponList = res.data.disabledCouponList;
               total.value = Math.ceil(
                  order_settle_info.platformCouponList.length / 8
               )
               order_settle_info.totalExpress = goods_data.data.reduce((total, prev) => {
                  if (prev.expressFee > 0) {
                     return total + prev.expressFee
                  } else {
                     return total + 0
                  }
               }, 0);


               //处理平台优惠券
               let default_plat_coupon = order_settle_info.platformCouponList.find((coupon) => coupon.checked)
               if (default_plat_coupon) {
                  platformCouponCode.value = default_plat_coupon.couponCode;
               } else {
                  platformCouponCode.value = "";
               }


            } else {
               ElMessage(res.msg);
            }
         })

      };

      //获取地址列表
      const getAddressList = () => {
         proxy
            .$get("v3/member/front/memberAddress/list")
            .then(res => {
               if (res.state == 200) {
                  address_list.data = res.data.list;
                  if (res.data.list.length > 0 && !orderAddress.data.addressId) {
                     var default_address = res.data.list.filter(function (item) {
                        return item.isDefault == 1;
                     });
                     if (default_address.length > 0) {
                        orderAddress.data = default_address[0];
                     } else {
                        orderAddress.data = res.data.list[0];
                     }
                     if (localStorage.getItem('addressId')) {
                        let addressID = localStorage.getItem('addressId')
                        if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                           orderAddress.data = res.data.list.filter(i => i.addressId == addressID)[0]

                        }
                     }
                  }
                  confirmOrder(1);
               } else {
                  confirmOrder(2);
                  ElMessage(res.msg);
               }
            })
      };
      //切换地址
      const selectAddress = index => {
         current_address_index.value = index;
      };
      //确认修改地址
      const confirmChangeAddress = () => {
         orderAddress.data = address_list.data[current_address_index.value];
         show_select_address.value = false;
         confirmOrder(2);
         localStorage.setItem('addressId', orderAddress.data.addressId)
      };
      //弹出地址选择框
      const changeAddress = () => {
         getAddressList();
         show_select_address.value = true;
      };
      //切换发票抬头
      const changeInvoiceTitleType = titleType => {
         invoice_info.data.titleType = titleType;
      };
      //切换发票类型
      const changeInvoiceType = invoiceType => {
         invoice_info.data.invoiceType = invoiceType;
      };


      watch(invoice_info.data, () => {
         invoice_info.data.registerPhone = invoice_info.data.registerPhone.toString().substring(0, 11)
         invoice_info.data.receiverMobile = invoice_info.data.receiverMobile.toString().substring(0, 11)
         invoice_info.data.bankAccount = invoice_info.data.bankAccount.toString().substring(0, 19)
      })



      //返回上一页‘
      const returnLastPage = () => {
         out_stock_dialog.value = false;
         router.go(-1);
      };

      //移除无货产品
      const clearFailureGoods = () => {
         proxy
            .$post("v3/business/front/cart/emptyInvalid")
            .then(res => {
               if (res.state == 200) {
                  out_stock_dialog.value = false;
                  confirmOrder(2);
                  ElMessage.success(res.msg);
               } else {
                  ElMessage(res.msg);
               }
            })
      };

      //校验订单状态
      const checkOrder = () => {
         if (isPreventClick.value) {
            return
         }
         isPreventClick.value = true
         proxy.$post(
            "v3/business/front/orderOperate/check",
            param,
            "application/json"
         ).then(res => {
            if (res.state == 267) {
               let { state } = res.data
               no_good_info.data = res.data;
               isPreventClick.value = false
               if (state == 7 || state == 4 || state == 3 || state == 2 || state == 1) {
                  out_stock_dialog.value = true;
               } else if (state == 5) {
                  proxy.$confirm(L['商品促销信息发生变化，请返回后重新下单'], L['商品促销信息发生变化'], {
                     confirmButtonText: L['返回'],
                     type: 'warning'
                  }).then(() => {
                     returnLastPage()
                  })
               }
            } else if (res.state == 200) {
               goBuy();
            } else {
               ElMessage(res.msg)
               isPreventClick.value = false
            }
         })

      }



      //跳转订单页
      const goBuy = () => {
         if (isPreventClick.value) {
            return
         }
         isPreventClick.value = true

         if (!orderAddress.data) {
            ElMessage.warning(L['请新建收货地址']);
            isPreventClick.value = false
            return;
         } else if (!shipments.value) {
            ElMessage.warning(L['请选择发货方式']);
            isPreventClick.value = false
            return;
         }

         let params = {
            addressId: orderAddress.data.addressId, //收货地址id
            deliverType: shipments.value, //发货类型，1-齐套发货；2-分批发货
            orderFrom: 1, //订单来源
            sets: route.query.sets ? route.query.sets : allData.data.sets,  //购买套数 默认1
            storeInfos: [], //店铺备注信息
            source: 3
         }

         //新加的参数----start
         if (route.query.productSets) {
            params.products = decodeURIComponent(route.query.productSets)
         }

         goods_data.data.map(item => {
            let storeInfosItem = {
               storeId: item.storeId, //商家ID
               purchaseSn: item.purchaseSn, //采购单号
               expectDeliverTime: item.expectDeliverTime ? item.expectDeliverTime + ' 23:59:59' : '', //期望交货日期
               orderRemark: item.orderRemark, //用户订单备注
               productInfos: [], //商品备注信息
               storeCouponCode: item.couponCode == L['不使用优惠券'] ? '' : item.couponCode

            }
            item.storeCartList.map(items => {
               let productInfosItem = {
                  customProductName: items.customProductName, //会员自定义产品名称
                  customSpec: items.customSpec, //会员自定义产品型号
                  goodsId: items.goodsId, //商品id
                  productId: items.productId, //sku id
               }
               storeInfosItem.productInfos.push(productInfosItem);
            })
            params.storeInfos.push(storeInfosItem);
         })

         if (route.query.applySn) {
            params.applySn = route.query.applySn
         }

         params.platformCouponCode = platformCouponCode.value


         proxy.$post("v3/business/front/orderOperate/submit", params, "json").then(res => {
            if (res.state == 200) {
               let paySn = res.data;
               proxy.$sldStatEvent({ behaviorType: 'buy' });
               if (allData.data.hasInquire) {
                  ElMessage.success(L['操作成功，待供应商审核'])
                  setTimeout(() => {
                     router.push('/member/order/quoteOrder')
                  }, 2000)
               } else {
                  timer.value = setInterval(() => {
                     queryPayState(paySn);
                  }, 200);
               }

            } else {
               ElMessage(res.msg);
               isPreventClick.value = false
            }
         })
      };

      // 定时查询是否可以支付
      const queryPayState = paySn => {
         getPayInfo(paySn);
      };
      //获取订单支付数据
      const getPayInfo = paySn => {
         proxy
            .$get("v3/business/front/orderOperate/process", {
               parentOrderSn: paySn
            })
            .then(res => {
               if (res.state == 200) {
                  isPreventClick.value = false
                  if (res.data.dealState == 3 && res.data.toPayPage) {
                     router.replace({
                        path: "Pay",
                        query: {
                           paySn: res.data.paySn,
                           payFrom: 1,
                           ifcart: pre_params.data.ifcart
                        }
                     });
                     //清除定时器
                     if (timer.value) {
                        clearInterval(timer.value);
                        timer.value = null;
                     }
                  } else if (res.data.dealState == 2) {
                     ElMessage(res.data.failReason || L['提交订单失败，请稍后重试']);
                     isPreventClick.value = false
                     //清除定时器
                     if (timer.value) {
                        clearInterval(timer.value);
                        timer.value = null;
                     }
                  } else if (res.data.dealState == 3 && !res.data.toPayPage) {
                     ElMessage.success(res.msg + L[',2s后自动跳转订单列表']);
                     //清除定时器
                     if (timer.value) {
                        clearInterval(timer.value);
                        timer.value = 0;
                     }
                     setTimeout(() => {
                        router.replace({
                           path: "/member/order/list"
                        });
                     }, 2000);
                  }
               } else if (res.state == 267) {
                  ElMessage.success(res.msg + L[',2s后自动跳转订单列表']);
                  //清除定时器
                  if (timer.value) {
                     clearInterval(timer.value);
                     timer.value = 0;
                  }
                  setTimeout(() => {
                     router.replace({
                        path: "/member/order/list"
                     });
                  }, 2000);
               } else {
                  //清除定时器
                  isPreventClick.value = false
                  if (timer.value) {
                     clearInterval(timer.value);
                     timer.value = 0;
                  }
                  ElMessage(res.msg);
               }
            })
      };

      onUnmounted(() => {
         if (timer.value) {
            clearInterval(timer.value);
            timer.value = 0;
         }
      });

      //关闭弹窗
      const close = () => {
         addressVisibleFlag.value = false;
      };

      //弹出新建地址窗口
      const addAddress = () => {
         addressVisibleFlag.value = true;
      };

      const refreshAddress = (addressInfo, addressId) => {
         orderAddress.data = addressInfo;
         orderAddress.data.addressId = addressId;
         localStorage.setItem('addressId', orderAddress.data.addressId)
      };


      const goNext = () => {
         out_stock_dialog.value = false;
         goBuy()
      }



      const editOpen = (id1, id2) => { //打开编辑物料信息
         goods_data.data[id1].storeCartList[id2].customProductNameEdit =
            goods_data.data[id1].storeCartList[id2].customProductName;
         goods_data.data[id1].storeCartList[id2].customSpecEdit =
            goods_data.data[id1].storeCartList[id2].customSpec;
         goods_data.data[id1].storeCartList[id2].editType = true;
      };
      const saveEdit = (id1, id2) => { //保存编辑物料信息
         goods_data.data[id1].storeCartList[id2].customProductName =
            goods_data.data[id1].storeCartList[id2].customProductNameEdit;
         goods_data.data[id1].storeCartList[id2].customSpec =
            goods_data.data[id1].storeCartList[id2].customSpecEdit;
         goods_data.data[id1].storeCartList[id2].customProductNameEdit = '';
         goods_data.data[id1].storeCartList[id2].customSpecEdit = '';
         goods_data.data[id1].storeCartList[id2].editType = false;
      };
      const cancleEdit = (id1, id2) => { //取消编辑物料信息
         goods_data.data[id1].storeCartList[id2].customProductNameEdit = '';
         goods_data.data[id1].storeCartList[id2].customSpecEdit = '';
         goods_data.data[id1].storeCartList[id2].editType = false;
      };

      const focusInput = (index) => {
         purchIndex.value = index;
      };
      const inputPurchase = (e) => {
         let regExp = /^[a-zA-Z0-9]+$/
         let regExp2 = /[a-zA-Z0-9]+/
         if (!regExp.test(e)) {
            ElMessage.error(L['请输入正确的采购单号!']);
            goods_data.data[purchIndex.value].purchaseSn =
               goods_data.data[purchIndex.value].purchaseSn.match(regExp2);
         }
      };
      const blurInput = () => {
         purchIndex.value = '';
      };
      const platformDiscount = val => {
         platformCouponCode.value = val
         confirmOrder(2)
      }

      //鼠标移入
      const previous = ind => {
         if (ind == 0) {
            previous_page.value = true
         } else if (ind == 1) {
            next_page.value = true
         }
      }
      //鼠标移出
      const previou = ind => {
         if (ind == 0) {
            previous_page.value = false
         } else if (ind == 1) {
            next_page.value = false
         }
      }
      //上一页
      const upper = () => {
         if (pagesize.value > 1) {
            let end_one = rise.value
            end.value = end_one - 1
            rise.value = end_one - 8
            pagesize.value = pagesize.value - 1
         }
      }
      //下一页
      const lower = () => {
         if (pagesize.value < total.value) {
            let rose_one = end.value
            rise.value = rose_one + 1
            end.value = rose_one + 8
            pagesize.value = pagesize.value + 1
         }
      }

      onMounted(() => {
         pre_params.data = route.query;
         getAddressList();
      });

      return {
         navlist,
         not_select,
         selected,
         show_change_address,
         invoice_selected,
         out_stock_dialog,
         orderAddress,
         changeAddress,
         show_select_address,
         address_list,
         current_address_index,
         selectAddress,
         confirmChangeAddress,
         goods_data,
         pre_params,
         invoice_info,
         changeInvoiceTitleType,
         changeInvoiceType,
         invoice_list,
         select_invoice_info,
         order_settle_info,
         confirmOrder,
         goBuy,
         returnLastPage,
         clearFailureGoods,
         no_good_info,
         addressVisibleFlag,
         close,
         type,
         refreshAddress,
         addAddress,
         couponCode,
         platformCouponCode,
         configInfo,
         rules,
         changeCoupon,
         allData,
         L,
         tmpInt,
         shipments,
         editOpen,
         saveEdit,
         cancleEdit,
         ...toRefs(dateState),
         purchIndex,
         focusInput,
         blurInput,
         inputPurchase,
         checkOrder,
         goNext,
         discountPlaform,
         platformDiscount,
         previous_page,
         next_page,
         rise,
         end,
         total,
         pagesize,
         curTab,
         previous,
         upper,
         previou,
         lower

      };
   },

   beforeRouteEnter(to, from, next) {
      // ...
      if (from.name == 'Login') {
         next('/')
      } else {
         next()
      }
   }
};
</script>

<style lang="scss" scoped>
@import "../../style/confirmOrder.scss";
</style>
<style lang="scss">
.inquire_text {
   text-align: right;
   margin-top: 25px;
   font-size: 14px;
}







.confirm_order_main {

   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
   }

   input[type='number'] {
      -moz-appearance: textfield;
   }

   .voice_width {
      width: 605px !important;
   }

   .add_voice_btn2 {
      margin: -30px 114px 10px !important;
   }

   .out_store_width {
      width: 480px !important;
   }

   .select_address_width {
      width: 500px !important;
   }

   .el-dialog__body {
      padding-top: 10px;
   }

   .el-input__inner {
      font-size: 12px;
      line-height: 1px !important;
      /**
     * 解决el-input设置类型为number时，中文输入法光标上移问题
     **/
   }

   .el-select .el-input__inner:focus {
      border-color: $colorMain;
   }

   .el-select .el-input.is-focus .el-input__inner {
      border-color: $colorMain;
   }

   .el-input.is-active .el-input__inner,
   .el-input__inner:focus {
      border-color: $colorMain;
      outline: 0;
   }

   .validateMessage {
      font-size: 12px;
      color: $colorPrice;
      margin-top: 5px;
      padding-left: 80px;
      height: 12px;
   }

   .giftProduct {
      display: inline-block;
      border: 1px solid $colorPrice;
      color: $colorPrice;
      width: 33px;
      height: 16px;
      border-radius: 3px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-right: 4px;
   }

   .integral_dialog .el-dialog__body {
      padding: 0;
   }

   #pre_tag_input {
      height: 30px;
      width: 320px;
   }

   .preMsgErr {
      color: $colorPrice;
      margin-left: 13px;
   }

   .el-radio {
      margin-right: 20px;
   }

   .el-radio__label {
      width: 70px;
      padding-left: 5px;
   }

   .good_logistics .el-input {
      margin-right: 15px;
   }

   .good_logistics .el-input__inner {
      width: 415px;
      height: 32px;
      padding-left: 8px;
   }

   .store_item_bottom_item .el-input {
      width: 600px;
      height: 32px;
      line-height: 32px;
   }

   .store_item_bottom_item .el-input__inner {
      height: 32px;
      line-height: 32px;
   }

   .store_item_bottom_item {
      .el-textarea {
         width: 600px;
         height: 64px;
         font-size: 12px;
      }

      .el-textarea__inner {
         height: 64px;
      }

      .el-input__icon {
         line-height: 35px;

      }
   }

   .store_item_bottom_date .el-input {
      width: 138px;
   }

   .receive_info_type .el-radio__input.is-checked .el-radio__inner {
      border-color: $colorMain;
      background: $colorMain;
   }

   .receive_info_type .el-radio__input.is-checked+.el-radio__label {
      color: $colorMain;
   }
}

.el-date-picker__header {
   .el-icon-arrow-right {
      margin: 8px 0 0;
   }
}

.el-popup-parent--hidden {
   overflow: visible;
   padding-right: 0 !important;
}
</style>